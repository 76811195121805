export default {
	computed: {
		isXS() {
			return this.$store.getters['isWindowXS'];
		},
		isSM() {
			return this.$store.getters['isWindowSM'];
		},
		isMD() {
			return this.$store.getters['isWindowMD'];
		},
		isLG() {
			return this.$store.getters['isWindowLG'];
		},
		isXL() {
			return this.$store.getters['isWindowXL'];
		},
		isWebkit() {
			console.log("ui", navigator.userAgent);
			return /webkit/i.test(navigator.userAgent) && !/chrome/i.test(navigator.userAgent);
		},
		iOSMobile() {
			const iOSMobile = [
					'iPad Simulator',
					'iPhone Simulator',
					'iPod Simulator',
					'iPad',
					'iPhone',
					'iPod'
				].includes(navigator.platform)
				// iPad on iOS 13 detection
				|| (navigator.userAgent.includes("Mac") && "ontouchend" in document);
			//console.log("IsIos iOSMobile ", iOSMobile);
			return iOSMobile;
		},
		isMacOs() {
			return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		}
	}
}