import Vue from 'vue';
import Vuetify from "vuetify";
import ru from 'vuetify/lib/locale/ru'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#363636',
                secondary: '#113443',
                third: '#F4F5FA',
            },
        },
    },
    lang: {
        locales: { ru, en },
        current: 'ru',
    },
});
