export function mergeObjects(...args) {
    let target = args[0];
    for (let i = 1; i < arguments.length; i++) {
        let source = arguments[i];
        for (let key in source) {
            if (source.hasOwnProperty(key)) {
                target[key] = source[key];
            }
        }
    }
    return target;
}

export function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    return array;
}

export function scrollTo(id, offset = 0) {
    const el = typeof id === "string" ? document.getElementById(id) : id;
    const top = el.getBoundingClientRect().top + window.scrollY + offset;
    window.scrollTo({
        top,
        behavior: "smooth"
    });
}

export function scrollToX(id, offset = 0) {
    const el = typeof id === "string" ? document.getElementById(id) : id;
    const left = el.getBoundingClientRect().left + offset;
    console.log("LEFT: ", left);
    window.scrollTo({
        left,
        behavior: "smooth"
    });
}