<template>
	<v-app id="app">
		<transition name="route" mode="out-in">
			<router-view/>
		</transition>
	</v-app>
</template>

<script>
	import vp from "@/mixins/vp";

	export default {
		name: 'App',
		mixins: [vp],
		methods: {
			onResize() {
				//console.log("App onResize");

				this.$store.commit('updateWindowWidth');
				this.$store.commit('updateWindowHeight');

				// на iOS чтобы попадало во весь экран на телефоне 100vh:
				//нужно добавить css переменную vh в main.scss:
				//:root {
				//--vh: 1vh;
				//}
				//после вместо vh использовать var(--vh, 1vh)
				//например всето 100vh = calc(var(--vh, 1vh)*100)
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty('--vh', `${vh}px`);
			},
		},
		mounted() {
			window.addEventListener("resize", this.onResize);
			this.onResize();
		},
		destroyed() {
			window.removeEventListener("resize", this.onResize);
		}
	};
</script>

<style lang="scss">
	#app {
		height: 100vh;
	}
</style>
