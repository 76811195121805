import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
    {
        path: '/', component: () => import('@/layout/layout-main.vue'),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/home.vue'),
                meta: {api: "HomeController", withHorizontalLayout: true}
            },
            {
                path: '/exhibitions',
                name: 'exhibitions',
                component: () => import('@/views/exhibitions.vue'),
                meta: {api: "ExhibitionsController", withNavPoints: true, withHorizontalLayout: true},
            },
            {
                path: '/exhibition/:code',
                props: true,
                name: 'exhibition',
                component: () => import('@/views/exhibition.vue'),
                meta: {api: "ExhibitionController", back: {to:{name:'exhibitions'}, name: "назад"}, withNavPoints: true, withHorizontalLayout: true },
            },
            /*{
                path: '/exhibition/branded/:code',
                props: true,
                name: 'exhibition-branded',
                component: () => import('@/views/exhibition-branded.vue'),
                meta: {api: "ExhibitionController", smallHeader: true},
            },*/
            {
                path: '/about',
                name: 'about',
                component: () => import('@/views/about.vue'),
                meta: {api: "AboutController", withHorizontalLayout: true, withZContent: true},
            },
            {
                path: '/contacts',
                name: 'contacts',
                component: () => import('@/views/contacts.vue'),
                meta: {api: "ContactController", withHorizontalLayout: true },
            },
            {
                path: '/partners',
                name: 'partners',
                component: () => import('@/views/partners.vue'),
                meta: {api: "PartnersController", withHorizontalLayout: true, withBlackHeader: true},
            },
            {
                path: '/news',
                name: 'news',
                component: () => import('@/views/news.vue'),
                meta: {api: "PubsController"},
            },
            {
                path: '/news/:code',
                props: true,
                name: 'news-item',
                component: () => import('@/components/news/news-item.vue'),
                meta: {api: "PubController", back: {to:{name:'news'}, name: "все новости"}},
            },
            {
                path: '/cookie',
                name: 'cookie',
                component: () => import('@/views/cookie.vue'),
                meta: {api: "CommonController"},
            },
            {
                path: "/privacy-policy",
                name: "privacy-policy",
                component: () => import("@/views/privacy-policy.vue"),
                meta: {api: "CommonController"},
            },
            {
                path: "/personal-data",
                name: "personal-data",
                component: () => import("@/views/personal-data.vue"),
                meta: {api: "CommonController"},
            },
            /*{
                path: '/how-to-watch',
                name: 'how-to-watch',
                component: () => import('@/views/how-to-watch.vue'),
                meta: {withoutFooter: true},
            },*/
        ]
    }
];

export default routes;
