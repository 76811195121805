/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'btn-share': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.875 5.239c0 .35.056.687.16 1.003L9.03 10.235a3.232 3.232 0 00-2.439-1.11 3.243 3.243 0 00-3.238 3.239 3.243 3.243 0 003.238 3.238c.972 0 1.845-.43 2.44-1.11l7.004 3.993a3.243 3.243 0 003.08 4.243 3.243 3.243 0 003.237-3.24 3.242 3.242 0 00-3.238-3.238c-.971 0-1.844.43-2.438 1.11L9.67 13.366a3.226 3.226 0 000-2.005l7.004-3.994a3.232 3.232 0 002.439 1.11 3.243 3.243 0 003.238-3.238A3.243 3.243 0 0019.114 2a3.243 3.243 0 00-3.239 3.239zm1.296 0c0-1.072.872-1.944 1.943-1.944 1.071 0 1.944.872 1.944 1.944a1.946 1.946 0 01-1.944 1.943 1.946 1.946 0 01-1.943-1.943zm0 14.25c0-1.072.872-1.943 1.943-1.943 1.071 0 1.944.871 1.944 1.943a1.945 1.945 0 01-1.944 1.943 1.945 1.945 0 01-1.943-1.943zM6.591 10.42c-1.07 0-1.943.871-1.943 1.943s.873 1.943 1.944 1.943c1.07 0 1.943-.871 1.943-1.943a1.945 1.945 0 00-1.943-1.943z" _fill="#151515"/>'
  }
})
