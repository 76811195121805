import Vue from 'vue';
import App from './app.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import vueFilters from './utils/vueFilters.js';
import "./styles/index.scss";
import "./plugins/svgicon";

Vue.config.productionTip = false
Vue.use(vueFilters);

new Vue({
  vuetify, router, store,
  render: h => h(App)
}).$mount('#app')
