export default {

    // request / response state
    response: {},	// объект ответа от БЕ в чистом виде
    session: {},	// данные текущей сессии
    common: {},			// общие для всех страниц
    page: {},			// индивидуальные для каждой страницы
    currentPage: {},
    isLoading: true,
    isInitialLoading: true,

    // UI state
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    scrollLeft: null,
    withBlackHeader: false,
    burgerDialogParams: {
        isVisible: false,
        menuItems: null,
      },

    audio: null,    // current audio playing,

    // language state
    currentLanguageCode: "ru"
};