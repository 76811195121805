import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';
import store from "@/store";
import {mergeObjects} from "@/utils/utils";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

function scrollTop() {
    const main = document.getElementById('layout');
    main.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant"
    });
    window.scrollTo({
        behavior: 'instant',
        left: 0,
        top: 0,
    });
};

router.beforeEach((to, from, next) => {

    // we need to fetch (1) user & (2) page data
    const ps = [];

    store.state.isLoading = true;

    // 1. загружаем данные юзаера (некэшируемые), они не зависят от роута:
    /*ps.push(new Promise((resolve, reject) => {
        store.dispatch('fetchSession').then(() => {
            // user data fetched
            // здесь не вызываем next() - его вызовем ниже после получения обои запросов
            console.log(resolve)
            resolve();
        }).catch(() => {
            console.warn("User data is empty...");

            // todo
            //reject();
            resolve();
        });
    }));*/

    // 2. загружаем данные текущего роута (кэшируемые), в т.ч. common данные:
    if (to.meta?.api) {
        //console.log('meta', to.meta?.api)
        ps.push(new Promise((resolve, reject) => {
            let params = mergeObjects({}, {
                action: to.meta.api,
                method: to.meta.method,
                params: mergeObjects({}, to.params, to.query),
            }, to.meta);
            store.dispatch('fetchPage', params)
                .then(() => {
                    // page data fetched
                    // здесь не вызываем next() - его вызовем ниже после получения обои запросов
                    //console.log('fetchPage')
                    resolve();
                })
                .catch((err) => {
                    console.error("API error: ", err);
                    reject();
                });
        }));
    }

    Promise.all(ps)
        .then(values => {
            //console.log("All fetched");
            next();
        })
        .catch(err => {
            // todo

            /*if (err.code === Config.API_EXCEPTION_NOT_FOUND) {
              router.replace({name: 'error404'});
            } else if (err.code == Config.ERROR_CODE_FORBIDDEN || err.code == Config.API_EXCEPTION_FORBIDDEN) {
              router.replace({name: 'login'});
            } else if (err.code === Config.API_EXCEPTION_SUSPENDED) {
              router.replace({name: 'report-accepted'});
            } else {
              router.replace({name: 'error500'});
            }*/
        });
});

router.afterEach(() => {

    store.state.withBlackHeader = false;
    store.state.burgerDialogParams.isVisible = false;

    setTimeout(() => {
        store.state.isLoading = false;
        scrollTop();

        setTimeout(() => {
            store.state.isInitialLoading = false;
        },700);
    }, 300);

});


export default router;
