/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'btn-arrow': {
    width: 16,
    height: 16,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M10.145 6H1m5.087 5L11 5.855 6.087 1" _stroke="#201E20" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
