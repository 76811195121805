export default {

    STORAGE_AUTH_TOKEN: "auth",
    API_SERVER_URI: '',

    //in _variables.scss, config.js and plugins/vuetify.js
    WIDTH_SM: 960,
    WIDTH_MD: 1280,
    WIDTH_LG: 1512,
    WIDTH_XL: 1920,
    WIDTH_XXL: 2560,

    YANDEX_API_KEY: "e26ae0eb-d93a-4eb4-85d6-699b565c1a24"
}